<template>
  <v-dialog
    v-model="showFormDialog"
    max-width="70vw"
    @click:outside="closeForm"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.company"
                  prepend-icon="house"
                  :label="$t('user.Company')"
                ></v-text-field>
                <v-text-field
                  v-model="form.contact"
                  prepend-icon="person"
                  :label="$t('user.Name')"
                ></v-text-field>
                <v-text-field
                  v-model="form.vat_id"
                  :label="$t('user.vat_id')"
                  :rules="rules.vat_id"
                ></v-text-field>
                <v-text-field
                  v-model="form.iban"
                  :label="$t('banks.table.iban')"
                  :rules="rules.iban"
                ></v-text-field>
                <v-text-field
                  v-model="form.bic"
                  :label="$t('banks.table.bic')"
                  :rules="rules.bic"
                ></v-text-field>
                <v-checkbox
                  v-model="form.autopay"
                  :label="$t('payouts.autopay')"
                ></v-checkbox>
                <v-select
                  v-model="form.use_bank"
                  :items="data.banks"
                  item-text="account_name"
                  item-value="id"
                  :label="$t('payouts.use_bank')"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.phone"
                  prepend-icon="mdi-phone-forward-outline"
                  :label="$t('user.Phone')"
                  :rules="rules.phone"
                ></v-text-field>
                <v-text-field
                  v-model="form.email"
                  :label="$t('user.Email')"
                  name="email"
                  prepend-icon="email"
                  type="text"
                  :disabled="sending || editedItem !== null"
                  :rules="rules.email"
                  @input="serverErrors = null"
                ></v-text-field>
                <v-text-field
                  v-model="form.addressbook_id"
                  :label="$t('user.Easyonline ID')"
                ></v-text-field>

                <v-text-field
                  v-model="form.password"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :label="$t('user.Password')"
                  name="password"
                  prepend-icon="lock"
                  :type="showPass ? 'text' : 'password'"
                  counter
                  :rules="rules.password"
                  :disabled="sending"
                  @click:append="showPass = !showPass"
                  @input="serverErrors = null"
                />
                <v-alert
                  v-if="editedItem !== null"
                  text
                  dense
                  type="info"
                  class="mt-5"
                  >{{ $t("user.Leave password") }}</v-alert
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray"
            :disabled="sending || savedItem != null"
            @click="closeForm"
            >{{ $t("button.Cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            :disabled="sending || savedItem != null"
            color="primary"
            >{{ $t("button.Save") }}</v-btn
          >
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
        errorMessage
      }}</v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0"
        >{{ $t("user.The user") }} {{ savedItem.id }} {{ $t("user.was saved") }}
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";
import validate from "@/plugins/validate";

export default {
  mixins: [crudMixin],
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      apiQuery: "/users/",
      showPass: false,
      modal: false,
      states: [],
      menu2: "",
      countries: [],
      banks: [],
      search: "",
      loading: false,
      gender: [
        {
          id: "1",
          name: "Male",
        },
        {
          id: "2",
          name: "Female",
        },
        {
          id: "3",
          name: "Other",
        },
      ],
      defaultItem: {
        name: "",
        email: "",
        password: "",
      },
      rules: {
        name: [(v) => !!v || "Required."],
        password: [
          (v) => (this.editedItem === null ? !!v || "Required." : true),
          (v) => (v ? v.length >= 8 || "Min 8 characters" : true),
          () =>
            this.serverErrors && this.serverErrors.password
              ? this.serverErrors.password[0]
              : true,
        ],
        email: [
          (v) => !!v || "Required.",
          (v) => {
            return validate.email.test(v) || "Invalid e-mail.";
          },
          () =>
            this.serverErrors && this.serverErrors.email
              ? this.serverErrors.email[0]
              : true,
        ],
        phone: [
          (v) => !!v || "Required.",
          (v) => {
            return validate.phone.test(v) || "Invalid phone.";
          },
          () =>
            this.serverErrors && this.serverErrors.phone
              ? this.serverErrors.phone[0]
              : true,
        ],
        iban: [
          (v) => {
            return !v || validate.iban.test(v) ? true : "Invalid iban";
          },
        ],
        bic: [
          (v) => {
            return !v || validate.bic.test(v) ? true : "Invalid bic";
          },
        ],
        vat_id: [
          (v) => {
            return !v || validate.vat_id.test(v) ? true : "Invalid Vat ID";
          },
        ],
      },
    };
  },
  created() {
    this.get("/countries").then((data) => {
      this.countries = data;
    });
  },

  computed: {
    formTitle() {
      return this.editedItem === null
        ? this.$t("user.add")
        : this.$t("user.edit");
    },
  },

  methods: {
    querySelections(v) {
      this.loading = true;

      this.items = this.states.filter((e) => {
        return (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1;
      });
      this.loading = false;
    },
  },
  watch: {
    date(val) {
      this.form.birth_date = val;
    },
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
  },
};
</script>
<style scoped>
.margin-btn {
  margin-bottom: 2rem;
}
</style>
