<template>
  <v-container id="user-table" fluid tag="section">
    <base-material-card
      icon="mdi-format-list-bulleted-square"
      :title="$t('user.users')"
    >
      <users-form ref="editForm" :data="meta" @itemSaved="onSaved"></users-form>

      <delete-dialog
        ref="deleteConfirm"
        :url="apiQuery"
        :title="$t('user.delete')"
        @itemDeleted="onDeleted"
      >
        <template v-slot:default="item"
          >{{ $t("questions.are you sure") }} {{ item.contact }}?</template
        >

        <template v-slot:error="error">
          <v-alert v-if="error.message" tile class="mb-0" type="error">{{
            error.message
          }}</v-alert>
        </template>

        <template v-slot:confirmed="item">
          <v-alert v-if="item.id" tile type="success" class="mb-0"
            >{{ $t("Account of") }} {{ item.name }} {{ $t("deleted") }}</v-alert
          >
        </template>
      </delete-dialog>

      <v-card>
        <v-data-table
          :headers="headers"
          :items="items"
          :page="page"
          :options.sync="options"
          :loading="loading"
          :server-items-length="total"
          :footer-props="{
            'items-per-page-options': [10, 20, 50],
          }"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-text-field
                @change="changeOutput"
                class="search-field"
                v-model="search"
                append-icon="search"
                :label="$t('search')"
                single-line
                solo
                hide-details
              ></v-text-field>
              <v-select
                v-model="balance"
                class="search-field"
                item-value="id"
                :label="$t('user.with balance')"
                hide-details
                :items="balances"
                @change="changeOutput"
              ></v-select>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark class="mb-2" @click="onAdd">
                <v-icon left>mdi-account-plus</v-icon>
                {{ $t("user.add") }}
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.balance="{ item }">
            <div v-for="(amount, currency) in item.balance" :key="currency">
              {{ amount }} {{ currency }}
            </div>
          </template>
          <template v-slot:item.fullname="{ item }">
            <p class="break-p">{{ item.contact }}</p>
          </template>
          <template v-slot:item.email="{ item }">
            <p class="break-p">{{ item.email }}</p>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="onEdit(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="viewProfile(item)"
              >mdi-eye</v-icon
            >
            <v-icon small class="mr-2" @click="viewTransactions(item)"
              >mdi-list-status</v-icon
            >
            <v-icon small @click="onDelete(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card>
    </base-material-card>
  </v-container>
</template>

<script>
import crudMixin from "mixins/crudTable";
import UsersForm from "./../users/UsersForm";
import crudMixin2 from "mixins/crudForm";
import DeleteDialog from "@/components/crud/DeleteDialog";

export default {
  components: {
    UsersForm,
    DeleteDialog,
  },
  mixins: [crudMixin, crudMixin2],
  data() {
    return {
      apiQuery: "/users/",
      date: new Date().toISOString().substr(0, 10),
      search: "",
      balance: "positive",
      page: 1,
      headers: [
        { text: "ID", align: "start", value: "id", filterable: false },
        { text: this.$t("user.Company"), value: "company" },
        { text: this.$t("user.Full name"), value: "fullname" },
        { text: this.$t("user.Email"), value: "email" },
        { text: this.$t("user.Phone"), value: "phone" },
        { text: this.$t("user.Balance"), value: "balance", align: "right" },
        { text: this.$t("user.Actions"), value: "actions", sortable: false },
      ],
      balances: [
        { text: this.$t("all"), id: null },
        { text: this.$t("user.positive balance"), id: "positive" },
        { text: this.$t("user.zero balance"), id: "zero" },
        { text: this.$t("user.negative balance"), id: "negative" },
      ],
    };
  },
  created() {
    // asta e pentru meta-datele necesare la formularul de editare
    this.get(this.apiQuery + "create/").then((data) => {
      this.meta = data;
    });
    this.$set(this.options, "balance", this.balance);
  },
  methods: {
    changeOutput() {
      this.$set(this.options, "search", this.search);
      this.$set(this.options, "balance", this.balance);
      this.options.page = 1;
    },
    showDetails() {},
    onSaved(item, index) {
      if (index === -1) {
        this.total++;
        item.isNew = true;
        this.items.push(item);
        window.setTimeout(() => {
          this.$refs.editForm.clearForm();
        }, 1500);
      } else {
        Object.assign(this.items[index], item);
        window.setTimeout(() => {
          this.$refs.editForm.closeForm();
        }, 2500);
      }
    },
    viewProfile(item) {
      this.$router.push("/pages/users/" + item.id + "/");
    },
    viewTransactions(item) {
      this.$router.push("/pages/users/" + item.id + "/transactions/");
    },
  },
};
</script>
<style scoped>
.break-p {
  word-break: break-word;
  max-width: 5rem;
}
.search-field {
  margin-bottom: 1rem;
  margin-right: 15rem;
}
</style>
