var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-table","fluid":"","tag":"section"}},[_c('base-material-card',{attrs:{"icon":"mdi-format-list-bulleted-square","title":_vm.$t('user.users')}},[_c('users-form',{ref:"editForm",attrs:{"data":_vm.meta},on:{"itemSaved":_vm.onSaved}}),_c('delete-dialog',{ref:"deleteConfirm",attrs:{"url":_vm.apiQuery,"title":_vm.$t('user.delete')},on:{"itemDeleted":_vm.onDeleted},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_vm._v(_vm._s(_vm.$t("questions.are you sure"))+" "+_vm._s(item.contact)+"?")]}},{key:"error",fn:function(error){return [(error.message)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(error.message))]):_vm._e()]}},{key:"confirmed",fn:function(item){return [(item.id)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"success"}},[_vm._v(_vm._s(_vm.$t("Account of"))+" "+_vm._s(item.name)+" "+_vm._s(_vm.$t("deleted")))]):_vm._e()]}}])}),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"page":_vm.page,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.total,"footer-props":{
          'items-per-page-options': [10, 20, 50],
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-text-field',{staticClass:"search-field",attrs:{"append-icon":"search","label":_vm.$t('search'),"single-line":"","solo":"","hide-details":""},on:{"change":_vm.changeOutput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{staticClass:"search-field",attrs:{"item-value":"id","label":_vm.$t('user.with balance'),"hide-details":"","items":_vm.balances},on:{"change":_vm.changeOutput},model:{value:(_vm.balance),callback:function ($$v) {_vm.balance=$$v},expression:"balance"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.onAdd}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_vm._v(" "+_vm._s(_vm.$t("user.add"))+" ")],1)],1)]},proxy:true},{key:"item.balance",fn:function(ref){
        var item = ref.item;
return _vm._l((item.balance),function(amount,currency){return _c('div',{key:currency},[_vm._v(" "+_vm._s(amount)+" "+_vm._s(currency)+" ")])})}},{key:"item.fullname",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"break-p"},[_vm._v(_vm._s(item.contact))])]}},{key:"item.email",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"break-p"},[_vm._v(_vm._s(item.email))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.onEdit(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewProfile(item)}}},[_vm._v("mdi-eye")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewTransactions(item)}}},[_vm._v("mdi-list-status")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v("mdi-delete")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }