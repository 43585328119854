/**
 * This mixin implements CRUD using API calls to Laravel
 */
export default {
  data() {
    return {
      showFormDialog: false,
      showUserDetails: false,
      form: {},
      defaultItem: {},
      valid: false,
      editedItem: null,
      editedIndex: -1,
      errorMessage: null,
      serverErrors: null,
      savedItem: null,
      rules: {},
    };
  },

  methods: {
    clearForm() {
      this.errorMessage = null;
      this.serverErrors = null;
      this.editedItem = null;
      this.savedItem = null;
      this.form = Object.assign({}, this.defaultItem);
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },

    closeForm() {
      this.showFormDialog = false;
      this.clearForm();
    },

    resetInputError(name) {
      if (this.serverErrors) {
        this.serverErrors[name] = null;
      }
    },

    getInputError(name, options = {}) {
      if (!this.serverErrors || !this.serverErrors[name]) {
        return true;
      }
      if (!options.name) {
        options.name = this.$t(name);
      }
      return this.$t(this.serverErrors[name][0], options);
    },

    showUserDialog(item, index) {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      this.editedItem = item;
      this.editedIndex = index;
      this.form = Object.assign({}, item);
      this.showFormDialog = true;
      this.showUserDetails = true;
    },
    closeUserDialog() {
      this.showUserDetails = false;
      this.clearForm();
    },
    addItem() {
      this.clearForm();
      this.showFormDialog = true;
    },

    editItem(item, index) {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      this.editedItem = item;
      this.editedIndex = index;
      this.form = Object.assign({}, item);
      this.showFormDialog = true;
    },

    saveItem() {
      this.errorMessage = null;
      this.serverErrors = null;
      this.savedItem = null;
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.editedItem === null) {
        this.post(this.apiQuery, this.form)
          .then((res) => {
            this.savedItem = res;
            this.$emit("itemSaved", res, -1);
          })
          .catch((error) => {
            this.errorMessage = error.message || error.error;
            this.serverErrors = error.errors;
            this.$refs.form.validate();
            this.$emit("serverError", error);
          });
      } else {
        this.patch(this.apiQuery + this.form.id, this.form)
          .then((res) => {
            this.savedItem = res;
            this.$emit("itemSaved", res, this.editedIndex);
          })
          .catch((error) => {
            this.errorMessage = error.message || error.error;
            this.serverErrors = error.errors;
            this.$refs.form.validate();
            this.$emit("serverError", error);
          });
      }
    },

    forSelect(obj) {
      const data = [];
      for (const i in obj) {
        data.push({
          id: i,
          text: obj[i],
        });
      }
      return data;
    },
  },
};
